.mail-compose {
    .list-contacts {
        padding: 10px 0;
        height: 350px;
        overflow-x: auto;

        .item-contact {
            padding: 5px;
            border: 1px solid #cacaca;

            .item-contact-email {
                color: #cacaca;
                font-size: 0.9em;
            }

            .el-icon-circle-plus-outline {
                font-size: 2em;
                color: $simp-green;
            }
            .el-icon-remove-outline {
                font-size: 2em;
                color: red;
            }
        }
    }

    .el-icon-circle-plus-outline {
        font-size: 2em;
        color: $simp-green;
    }

    .el-dialog {
        .el-dialog__body,
        .el-dialog__title {
            color: #ffffff;
        }

        background-color: #000000;
        color: #ffffff;
        border: 1px solid #efefef;
    }
    .el-tag:first-child,
    .el-tag + .el-tag {
        margin-right: 5px;
    }
    .el-tabs--border-card>.el-tabs__header,
    .el-tabs--border-card {
        background: none;
    }
    .el-tabs--border-card {
        border: none;
    }
    .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
        color: $simp-green;
        background-color: transparent;
        border-color: transparent;
    }
    .button-new-tag {
        margin-left: 10px;
        height: 32px;
        line-height: 30px;
        padding-top: 0;
        padding-bottom: 0;
    }
    .input-new-tag {
        width: 150px;
        margin-left: 10px;
        vertical-align: bottom;
    }
    .el-button--text {
        color: #ffffff;
    }
    .el-tag .el-icon-close::before {
        color: red;
    }
    .el-card {
        background-color: transparent;
        color: #ffffff;
    }
}

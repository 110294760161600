.page-webmail {
    .el-header {
        background-color: transparent;
        color: #ffffff;
        height: 20px;
    }
    .el-aside {
        color: #ffffff;
        border-right: solid 0 #e6e6e6;
        background-color: transparent;
    }
    .el-main {
        max-height: calc(100vh - 100px);
        background-color: rgba(51,51,51,0.8);
        -webkit-border-radius: 16px;
        border-radius: 16px;
        margin-bottom: 16px;
    }
    .el-menu {
        border-right: none;
        list-style: none;
        position: relative;
        padding-left: 0;
        background-color: transparent;
        margin-top: 20px 0 0 0;

        .el-menu-item {
            color: #ffffff;

            &:focus,
            &:hover {
                background-color: transparent;
            }
            &.is-active {
                color: inherit;

                .menu-item {
                    background-color: rgba(255,255,255,.3);
                }
            }
        }
    }
    .el-table__row.el-table__row--striped .el-table__body tr .el-table__row td,
    .el-table__row .el-table__body tr.el-table__row td {
        background-color: #191818!important;
    }
    .el-table__row.el-table__row--striped .el-table__body tr.el-table__row td:hover,
    .el-table__row .el-table__body tr.el-table__row td:hover {
        color: #ffffff!important;
        -moz-box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.5) inset;
        -webkit-box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.5) inset;
        box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.5) inset;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped td.el-table__cell {
        background-color: #191818!important;
    }
    .el-table--striped .el-table__body tr.el-table__row--striped:hover td.el-table__cell {
        color: #191818!important;
        -moz-box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.5) inset;
        -webkit-box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.5) inset;
        box-shadow: 0 4px 2px -3px rgba(0, 0, 0, 0.5) inset;
        background-color: #ffffff!important;
    }
    .el-table .cell {
        white-space: nowrap;
    }
    .mail-inbox tbody tr {
        cursor: pointer;
    }
    .mail-sent > div,
    .mail-inbox > div {
        width: 100%;
    }
    .message-details {
        background-color: #ffffff;
        color: #000000;
        padding:10px;
        margin-top: 10px;
        border-radius: 3px;
        overflow: scroll;
    }
    .mail-inbox .unread {
        font-weight: bold;
    }
    .mail-compose > form {
        width: 100%;
    }
    .el-form-item__label {
        color: #ffffff;
    }
    .quillWrapper {
        background-color: #ffffff;
        color:#000000;
        border-radius: 4px;
        border: 1px solid #ffffff;
    }
    ul.list {
        padding: 0;

        li {
            list-style: none;
            padding: 0;
        }
    }
    .compose-menu-item {
        -webkit-transition: box-shadow .08s linear,min-width .15s cubic-bezier(0.4,0,0.2,1);
        transition: box-shadow .08s linear,min-width .15s cubic-bezier(0.4,0,0.2,1);
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
        box-shadow: 0 1px 2px 0 rgb(60 64 67 / 30%), 0 1px 3px 1px rgb(60 64 67 / 15%);
        -webkit-font-smoothing: antialiased;
        font-family: "Google Sans",Roboto,RobotoDraft,Helvetica,Arial,sans-serif;
        font-size: .875rem;
        letter-spacing: normal;
        -webkit-align-items: center;
        align-items: center;
        background-color: #fff;
        background-image: none;
        -webkit-border-radius: 16px;
        border-radius: 16px;
        color: #5f6368;
        display: -webkit-inline-box;
        display: -webkit-inline-flex;
        display: inline-flex;
        font-weight: 500;
        height: 56px;
        min-width: 130px;
        overflow: hidden;
        padding: 0 24px 0 15px;
        text-transform: none;
        margin-bottom: 8px;
    }
    .el-menu {
        padding-right: 4px;
    }
    .el-menu-item {
        height: auto;
        padding: 0 !important;
    }
    .menu-item {
        -webkit-transition: border-radius .15s cubic-bezier(0.4,0,0.2,1),margin .15s cubic-bezier(0.4,0,0.2,1),width .15s cubic-bezier(0.4,0,0.2,1);
        transition: border-radius .15s cubic-bezier(0.4,0,0.2,1),margin .15s cubic-bezier(0.4,0,0.2,1),width .15s cubic-bezier(0.4,0,0.2,1);
        -webkit-align-items: center;
        align-items: center;
        display: -webkit-box;
        display: -webkit-flex;
        display: flex;
        height: 32px;
        padding: 0 12px 0 14px;
        -webkit-transition: margin .15s cubic-bezier(0.4,0,0.2,1),padding .15s cubic-bezier(0.4,0,0.2,1);
        transition: margin .15s cubic-bezier(0.4,0,0.2,1),padding .15s cubic-bezier(0.4,0,0.2,1);
        font-size: .875rem;
        border-radius: 16px;

        &:focus,
        &:hover {
            background-color: rgba(255,255,255,.3);
        }

        i {
            margin-right: 18px;
        }
    }
    .mail-inventory-selector .el-card {
        background-color: transparent;
        color: #ffffff;
    }
    .mail-template-selector {
        .el-tabs--border-card>.el-tabs__header,
        .el-tabs--border-card {
            background: none;
        }
        .el-tabs--border-card {
            border: none;
        }
        .el-tabs--border-card>.el-tabs__header .el-tabs__item.is-active {
            color: $simp-green;
            background-color: transparent;
            border-color: transparent;
        }
    }
    .ProseMirror {
        float: none;

        &:focus,
        &:active {
            outline: none;
        }
        /*> * + * {
            margin-top: 0.75em;
        }*/
        img {
            //width: 100%;
            //height: auto;
            //display: block;
           // margin-left: auto;
            //margin-right: auto;
            &.ProseMirror-selectednode {
                outline: 3px solid #68cef8;
            }
        }
        .custom-image-small {
            max-width: 200px;
        }
        .custom-image-medium {
            max-width: 75%;
        }
        .custom-image-large {
            width: 100%;
        }
        .custom-image-25 {
            width: 25%;
        }
        .custom-image-50 {
            width: 50%;
        }
        .custom-image-75 {
            width: 75%;
        }
        .custom-image-100 {
            width: 100%;
        }
        .custom-image-center {
            display: block;
            margin-left: auto;
            margin-right: auto;
        }
        .custom-image-left {
            display: block;
            margin-left: 0;
            margin-right: auto;
        }
        .custom-image-right {
            display: block;
            margin-left: auto;
            margin-right: 0;
        }
        table tr td {
            padding: 5px
        }
    }
    .bubble-menu {
        display: flex;
        background-color: #0d0d0d;
        padding: 0.2rem;
        border-radius: 0.5rem;
        button {
            border: none;
            background: none;
            color: #fff;
            font-size: 0.85rem;
            font-weight: 500;
            padding: 0 0.2rem;
            opacity: 0.6;
            &:hover,
            &.is-active {
                opacity: 1;
            }
            &.is-active {
                text-decoration: underline;
            }
        }
    }
}
